// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brigady-js": () => import("./../../../src/pages/brigady.js" /* webpackChunkName: "component---src-pages-brigady-js" */),
  "component---src-pages-foto-js": () => import("./../../../src/pages/foto.js" /* webpackChunkName: "component---src-pages-foto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-templates-wp-gallery-js": () => import("./../../../src/templates/WpGallery.js" /* webpackChunkName: "component---src-templates-wp-gallery-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WpPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */)
}

